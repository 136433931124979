import React from 'react'

const Newsletter = ({ data = {} }) => {
  const { newsletterID } = data || {}
  if (!newsletterID) return null

  return (
    <div className="footer--newsletter">
      <div id={newsletterID} className={newsletterID} />
    </div>
  )
}

export default Newsletter
