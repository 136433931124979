import React, { useEffect, useRef, useState } from 'react'
import { m } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import Link from 'next/link'
import cx from 'classnames'

import Listbox from '@components/listbox'
import {
  useToggleCart,
  useToggleWishlist,
  useCartCount,
  useSiteContext,
  useSetCurrency,
} from '@lib/context'

// import PromoBar from '@components/promo-bar'
import Menu from '@components/menu'
import Icon from '@components/icon'
import { currencies, isBrowser, isPrimaryStore, isUSStore } from '@lib/helpers'
import { leftTrayAnim } from '@lib/animate'
import { useRouter } from 'next/router'
import Image from 'next/image'

const Header = ({ data = {} }) => {
  // expand our header data
  const { menuDesktop, menuMobile } = data || {}
  const headerRef = useRef()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const { toggleWishlist } = useToggleWishlist()

  // setup menu toggle event
  const toggleMobileNav = (state) => {
    setMobileNavOpen(state)
    if (isBrowser) document.body.classList.toggle('overflow-hidden', state)
  }

  const getDesktopItems = () => {
    return [
      { page: { isHome: true, slug: 'home', type: 'page' }, title: 'Home' },
      ...(menuDesktop?.items || []),
    ]
  }

  const getMobileItems = () => {
    return [
      ...(menuDesktop?.items || []),
      {
        title: 'Wishlist',
        onClick: (e) => {
          e.preventDefault()
          toggleWishlist(true)
          toggleMobileNav(false)
        },
      },
      ...(menuMobile?.items || []),
    ]
  }

  useEffect(() => {
    if (isBrowser) {
      let lastScrollY = 0
      const handleScroll = () => {
        const { scrollY } = window
        const scrollDelta = scrollY <= 0 ? 0 : scrollY - lastScrollY
        const { classList: bodyClasses } = document.body
        const { classList: headerClasses } = headerRef?.current || {}

        if (headerClasses) {
          // Border bottom class
          if ([...headerClasses].includes('is-scrolled') && scrollY <= 0) {
            headerClasses.remove('is-scrolled')
          } else if (scrollY > 0) {
            headerClasses.add('is-scrolled')
          }

          // Hide under browser window class
          if ([...bodyClasses].includes('header-hidden') && scrollDelta <= 0) {
            bodyClasses.remove('header-hidden')
          } else if (scrollDelta > 0) {
            bodyClasses.add('header-hidden')
          }
        }
        lastScrollY = scrollY
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className="header" ref={headerRef}>
      <div className="header--content">
        <div className="header--logo">
          <Link href="/" scroll={false} aria-label="Go home">
            <Icon
              name="Logo"
              className="header--logo--icon"
              viewBox="0 0 100 13"
              preserveAspectRatio="xMinYMin meet"
            />
          </Link>
        </div>
        <nav className="header--nav">
          <FocusTrap active={isMobileNavOpen}>
            <div className="header--nav--mobile">
              <button
                onClick={() => toggleMobileNav(!isMobileNavOpen)}
                className={cx('header--nav--toggle', {
                  'is-open': isMobileNavOpen,
                })}
                aria-expanded={isMobileNavOpen}
                aria-label="Toggle Menu"
              >
                <hr />
                <hr />
                <hr />
                <hr />
              </button>
              <m.div
                initial="hide"
                animate={isMobileNavOpen ? 'show' : 'hide'}
                variants={leftTrayAnim}
                className="header--nav--mobile--content"
              >
                <span className="block md:hidden">
                  <Currencies />
                </span>
                <button
                  onClick={() => toggleMobileNav(!isMobileNavOpen)}
                  className="header--nav--close"
                  aria-label="Close Menu"
                >
                  <Icon name="Cross" color="black" />
                </button>
                <Menu
                  items={getMobileItems()}
                  onClick={() => toggleMobileNav(false)}
                />
              </m.div>
              <div
                className={cx('backdrop', {
                  'is-active': isMobileNavOpen,
                })}
                onClick={() => toggleMobileNav(false)}
              />
            </div>
          </FocusTrap>
          <div className="header--nav--desktop">
            <Menu items={getDesktopItems()} className="-ml-4 is-topnav" />
          </div>
          <div className="header--nav--options h-full">
            <div className="flex items-center gap-3 lg:gap-4">
              <WishlistToggle />
              <Search />
              <CartToggle />
              <span className="hidden md:block">
                <Currencies />
              </span>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

const Search = () => {
  const router = useRouter()
  const [query, setQuery] = useState(router.query.q)
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (query?.length > 0) {
      router.push({
        pathname: '/search',
        query: { q: query },
      })
      inputRef.current.blur()
      setQuery('')
      setIsOpen(false)
    } else {
      setIsOpen(true)
      inputRef.current.focus()
    }
    return
  }

  // set isOpen to false when clicking outside of the search form
  const searchRef = useRef()
  useEffect(() => {
    const handleClick = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('pointerdown', handleClick)
    return () => document.removeEventListener('pointerdown', handleClick)
  })

  return (
    <form
      className={cx('header--search', { 'is-open': isOpen })}
      onSubmit={handleSubmit}
      ref={searchRef}
    >
      <input
        type="text"
        id="query"
        name="q"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        ref={inputRef}
      />
      <button type="submit" className="!flex font-medium">
        <Image src="/assets/search.svg" alt="Search" width={16} height={16} />
        <span className="ml-2 hidden lg:block">Search</span>
      </button>
    </form>
  )
}

const CartToggle = () => {
  const toggleCart = useToggleCart()
  const cartCount = useCartCount()

  return (
    <button
      className="!flex items-center justify-center text-sm gap-2 z-20 font-medium"
      onClick={() => toggleCart()}
    >
      <Image
        src="/assets/basket.svg"
        alt="Basket"
        width={16}
        height={16}
        style={{
          transform: 'translateY(-2px)',
        }}
      />
      <span className="hidden lg:block">Basket</span>
      {cartCount > 0 && <span className="hidden lg:block">({cartCount})</span>}
    </button>
  )
}

const WishlistToggle = () => {
  const { toggleWishlist, wishlist } = useToggleWishlist()

  return (
    <button
      className="!flex p-0 items-center justify-center text-sm gap-2 z-20 font-medium"
      onClick={() => toggleWishlist()}
    >
      <Image src="/assets/heart.svg" alt="Wishlist" width={16} height={16} />
      <span className="hidden lg:block">Wishlist</span>
      {wishlist.length > 0 && (
        <span className="hidden lg:block">({wishlist.length})</span>
      )}
    </button>
  )
}

const Currencies = () => {
  const { currentCurrency } = useSiteContext()
  const setCurrency = useSetCurrency()

  const handleChange = (option) => {
    setCurrency(option[0].value)
  }

  const activeOption = currentCurrency?.currencyCode
  const options = currencies.map((currency) => ({
    title: currency.currencyCode,
    slug: currency.currencyCode,
  }))

  // if (!currentCurrency || !isPrimaryStore()) return null
  return (
    <div className="header--currency">
      <Listbox
        id="currency"
        label="Select currency"
        name="currency"
        options={options}
        activeOption={activeOption}
        onChange={handleChange}
      />
    </div>
  )
}

export default Header
