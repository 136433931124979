import React from 'react'
import cx from 'classnames'

import Photo from '@components/photo'
import CustomLink from '@components/link'

export const blockSerializers = {
  block: (props) => {
    const { markDefs, style = 'normal' } = props.value

    // check if our block contains a button
    const hasButton =
      markDefs &&
      markDefs.some((c) => c._type === 'link' && c.isButton === true)

    // build our mock header styles
    if (style === 'lead') {
      return <p className="lead">{props.children}</p>
    }

    // build our mock header styles
    if (style === 'h1mock') {
      return (
        <p className={cx('is-h1', { 'has-btn': hasButton })}>
          {props.children}
        </p>
      )
    }

    if (style === 'h2mock') {
      return (
        <p className={cx('is-h2', { 'has-btn': hasButton })}>
          {props.children}
        </p>
      )
    }

    if (style === 'h3mock') {
      return (
        <p className={cx('is-h3', { 'has-btn': hasButton })}>
          {props.children}
        </p>
      )
    }

    if (style === 'h4mock') {
      return (
        <p className={cx('is-h4', { 'has-btn': hasButton })}>
          {props.children}
        </p>
      )
    }

    // go through our remaing, true header styles
    if (/^h\d/.test(style)) {
      return React.createElement(
        style,
        { className: hasButton ? 'has-btn' : null },
        props.children
      )
    }

    return <p>{props.children}</p>
  },
  types: {
    photo: ({ value }) => {
      return <Photo photo={value} />
    },
    horizontalRule: () => <hr />,
  },
  marks: {
    link: ({ value, children }) => {
      return <CustomLink link={{ ...value, ...{ title: children[0] } }} />
    },
  },
}
