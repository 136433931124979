import React, { useState, useRef, useEffect } from 'react'
import { useIntersection } from 'use-intersection'
import cx from 'classnames'

import { buildSrc, useWindowSize, mdMax, isBrowser } from '@lib/helpers'

const Photo = ({
  photo,
  layout,
  quality = 65,
  retina = false,
  forceLoad,
  onLoad,
  className,
  style,
  hasPlaceholder = true,
  init = true,
  imagePriority = false,
}) => {
  if (!photo?.asset) return null
  const DPR = isBrowser && retina ? window?.devicePixelRatio : 1
  const {
    width: _width,
    height: _height,
    sidebarWidth,
    overlayWidth,
  } = useWindowSize()
  const vw = _width
  const vh = _height
  const isMd = vw <= mdMax
  const cols =
    isBrowser && document.body.classList.contains('is-collection') ? 3 : 4 || 4

  const imageRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const isIntersecting = useIntersection(imageRef, {
    once: true,
    rootMargin: '200px',
  })
  const isPlaceholderIntersecting = useIntersection(imageRef, {
    once: true,
    rootMargin: '400px',
  })
  const [showing, setShowing] = useState(init)
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [src, setSrc] = useState(buildSrc(photo, { ...{ quality } }))

  useEffect(() => {
    let width, height
    switch (layout) {
      case 'product':
        height = isMd ? vh : vh * 0.75
        break
      case 'product-card':
        width = vw / (isMd ? 1.5 : cols * 2)
        break
      case 'product-card-hover':
      case 'product-card-frames':
        width = vw / ((isMd ? 0.33 : 1) * cols)
        height = width
        break
      case 'product-card-frames-hover':
        width = (vw - sidebarWidth) / ((isMd ? 0.33 : 1) * cols)
        break
      case 'spotlight':
        width = vw / (isMd ? 1 : 2)
        height = width * 1.33
        break
      case 'spotlight-product':
        width = vw / (isMd ? 2 : 4)
        break
      case 'spotlight-lg':
        width = vw
        height = isMd ? vh : width / 2
        break
      case 'story':
        height = vh * 0.8
        width = height * 0.6
        break
      case 'story-card':
        width = isMd ? vw : (vw - sidebarWidth) / 4
        height = width * 1.33
        break
      case 'gallery-half':
        width = isMd ? vw : (vw - sidebarWidth) / 2
        height = width * 1.33
        break
      case 'gallery-full':
        width = vw - (isMd ? 0 : sidebarWidth)
        height = width * 0.66
        break
      case 'artist-overlay':
        width = overlayWidth
        height = overlayWidth * 0.5
        break
      case 'article-thumbnail':
        height = 160
        width = 151
        break
      default:
        width = isMd ? mdMax : 1440
        height = width * 1.33
        break
    }

    if (width) {
      setWidth(width * DPR)
    } else {
      setWidth(width)
    }
    if (height) {
      setHeight(height * DPR)
    } else {
      setHeight(height)
    }
  }, [isIntersecting, vw, vh, mdMax, sidebarWidth, overlayWidth, layout, DPR])

  useEffect(() => {
    // define our src and srcset
    const src = buildSrc(photo, {
      ...{ width },
      ...{ height },
      ...{ quality },
    })

    setSrc(src)
  }, [width, height, quality, photo])

  // handle our image onLoad
  function handleLoad() {
    requestAnimationFrame(() => {
      setIsLoaded(true)
      if (onLoad) onLoad()
    })
  }

  useEffect(() => {
    if (init) setShowing(true)
  }, [init])

  return (
    <picture className={cx('ar', className)} style={style} ref={imageRef}>
      {showing && (forceLoad || isIntersecting) && (
        <img
          width={width}
          height={height}
          src={src}
          priority={imagePriority}
          onLoad={handleLoad}
          sizes={'(min-width: 1024px) 90vw, 100vw'}
          // sizes={spotlight && '(min-width: 1024px) 90vw, 100vw'}
          placeholder={photo?.lqip ? 'blur' : undefined}
          blurDataURL={photo?.lqip}
          alt={photo?.alt || photo?.asset?.altText || 'Image'}
          className={cx('ar--primary', { 'is-loaded': isLoaded })}
        />
      )}
      {hasPlaceholder && (forceLoad || isPlaceholderIntersecting) && (
        <img
          src={photo?.lqip}
          alt="Image"
          role="presentation"
          className={cx('ar--placeholder', { 'is-loaded': isLoaded })}
        />
      )}
    </picture>
  )
}
export default Photo
