import React from 'react'

import { buildSrc } from '@lib/helpers'
import CustomLink from '@components/link'
import Newsletter from '@components/newsletter'
import Menu from '@components/menu'
import Icon from '@components/icon'
import BlockContent from '@components/block-content'

const Footer = ({ data = {} }) => {
  const { sellingpoints, cookiesPage, blocks, newsletter, social } = data || {}

  return (
    <footer id="footer" className="footer" role="contentinfo">
      <div className="footer--usps">
        {sellingpoints?.map((s, key) => (
          <div className="footer--usps--item" key={key}>
            <img
              loading="lazy"
              src={buildSrc(s.icon.asset)}
              className="mb-10"
            />
            <BlockContent blocks={s.body} />
          </div>
        ))}
      </div>
      <div className="footer--grid">
        <div className="footer--block">
          <div className="menu-social">
            {social?.map((link, key) => {
              return (
                link.showInFooter && (
                  <a
                    key={key}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={link.icon} />
                  </a>
                )
              )
            })}
          </div>
          <Newsletter data={newsletter} />
        </div>
        {blocks?.map((block, key) => (
          <div key={key} className="footer--block">
            <div>
              {block.title && <p className="font-medium">{block.title}</p>}
            </div>
            {block.menu?.items && <Menu items={block.menu.items} />}
          </div>
        ))}
      </div>
      <div className="footer--disclaimer">
        <div>&copy; {new Date().getFullYear()}</div>
        {cookiesPage && <CustomLink link={cookiesPage} />}
      </div>
    </footer>
  )
}

export default Footer
