import React, { useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'

import CustomLink from '@components/link'
import { getPageLink, getPageSlugs, useWindowSize, xsMax } from '@lib/helpers'
import { dropdownAnim, rightTrayAnim } from '@lib/animate'
import Icon from './icon'
import { buildSrc } from '../lib/helpers'

const getIsActive = (router, link) => {
  const {
    asPath: url,
    query: { slug = [] },
  } = router
  if (!link?.page) return false
  return (
    getPageLink(link) === url ||
    (link?.page && slug[0] === getPageSlugs(link.page)[0])
  )
}

const Menu = ({ items, isArtist = false, onClick, ...rest }) => {
  const router = useRouter()
  if (!items) return null

  return (
    <ul {...rest}>
      {items.map((item, key) => {
        const isDropdown = !!item.columns

        // Dropdown List
        if (isDropdown) {
          return <MenuDropdown key={key} item={item} onClick={onClick} />
        } else {
          // Single link
          const isActive = getIsActive(router, item)
          return (
            <li
              key={key}
              className={cx(
                'nav-item !flex flex-row items-center justify-start gap-3',
                { 'is-active': isActive }
              )}
            >
              <CustomLink link={item} onClick={item?.onClick || onClick}>
                {item.title}
              </CustomLink>
            </li>
          )
        }
      })}
    </ul>
  )
}

const ArtistsMenu = ({
  content: { pinnedArtists, artists, amountOfArtists, browseAllLink, onClick },
}) => {
  const items = [...pinnedArtists, ...artists]
    .slice(0, amountOfArtists)
    .map((a) => ({
      ...a,
      page: { type: 'artist', slug: a.slug },
    }))

  return (
    <ul>
      {items.map((item, key) => (
        <li
          key={key}
          className={cx(
            'nav-item !flex flex-row items-center justify-start gap-3'
            // { 'is-active': isActive }
          )}
        >
          {item.photo && (
            <img
              src={buildSrc(item.photo, { width: 24, height: 24 })}
              className="!w-6 !h-6 object-cover rounded-full overflow-hidden"
              width={24}
              height={24}
              alt="Artist Photo"
            />
          )}
          <CustomLink
            link={item}
            onClick={item?.onClick || onClick}
          ></CustomLink>
        </li>
      ))}

      {browseAllLink && (
        <li className="mt-2 hidden md:block">
          <CustomLink
            className="btn is-outlined is-small !inline-block hover:border-black"
            link={browseAllLink}
          >
            <svg
              width="15"
              height="4"
              viewBox="0 0 15 4"
              fill="black"
              className="mr-2"
            >
              <circle cx="1.5" cy="1.92969" r="1.5" />
              <circle cx="7.5" cy="1.92969" r="1.5" />
              <circle cx="13.5" cy="1.92969" r="1.5" />
            </svg>
            {browseAllLink.title}
          </CustomLink>
        </li>
      )}
    </ul>
  )
}

const MenuDropdown = ({ item, onClick }) => {
  const [isOpen, setIsOpen] = useState()
  const router = useRouter()
  const isActive =
    getIsActive(router, item.link) ||
    item.columns.some((c) => c.items?.some((item) => getIsActive(router, item)))
  const { width: vw } = useWindowSize()

  const enter = () => {
    setTimeout(() => setIsOpen(true))
  }

  const hide = () => {
    setTimeout(() => setIsOpen(false))
  }

  const close = () => {
    setIsOpen(false)
    if (onClick) onClick()
  }

  return (
    <li
      className={cx('nav-item is-dropdown', { 'is-active': isActive })}
      onMouseOver={enter}
      onMouseOut={hide}
    >
      {item.link?.page ? (
        <CustomLink link={item.link} onClick={enter} />
      ) : (
        <span className="nav-item--empty" onClick={enter}>
          {item.link?.title}
        </span>
      )}
      {isOpen && (
        <div className={cx('header--nav--dropdown', { 'is-open': isOpen })}>
          <div
            className="header--nav--dropdown--content"
            initial="hide"
            animate={isOpen ? 'show' : 'hide'}
            variants={vw < xsMax ? rightTrayAnim : dropdownAnim}
          >
            <div className="header--nav--dropdown--header">
              <button onClick={hide}>
                <Icon name="ChevronLeft" className="w-4 h-4" />
                Back
              </button>
            </div>
            <div className="header--nav--dropdown--rows">
              {item.columns?.map((column, key) => {
                const isArtistsColumn = column.type === 'artistsColumn'

                return (
                  <div key={key}>
                    <div className="font-medium mb-5">
                      {isArtistsColumn ? 'Artists' : column?.title}
                    </div>
                    {isArtistsColumn ? (
                      <ArtistsMenu content={column} onClick={close} />
                    ) : (
                      <Menu items={column.items} onClick={close} />
                    )}
                  </div>
                )
              })}
              {item.featured && (
                <div className="featured relative">
                  <CustomLink
                    link={item.featured.link}
                    className="absolute !p-0 inset-0 z-10 cursor-pointer"
                  />
                  <div className="flex items-center justify-between mb-6">
                    <div className="font-sm font-medium">
                      {/* {item.featured.link.title} */}
                    </div>
                    <div className="pill is-sm is-outlined hover:!no-underline">
                      Featured
                    </div>
                  </div>
                  <div
                    className="relative"
                    style={{
                      aspectRatio: 1.3,
                    }}
                  >
                    <img
                      src={buildSrc(item.featured.photo)}
                      srcSet={[300, 600, 900, 1200].map((w) => `${buildSrc(item.featured.photo, { width: w })} ${w}w`).join(', ')}
                      className="object-cover absolute left-0 top-0 w-full h-full"
                      alt="Featured image"
                      sizes="33vw"
                    />
                    <div className="absolute left-0 bottom-0 pb-5 px-5 text-white text-xs">
                      {item.featured.caption}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="backdrop is-header" />
        </div>
      )}
    </li>
  )
}

export default Menu
