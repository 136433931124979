import React, { useEffect } from 'react'
import Script from 'next/script'
import { m } from 'framer-motion'

import { isBrowser, useWindowSize } from '@lib/helpers'
import { pageTransitionSpeed } from '@lib/animate'

import HeadSEO from '@components/head-seo'
import Header from '@components/header'
import Footer from '@components/footer'
import { useRouter } from 'next/router'

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionSpeed / 1000,
      delay: 0.2,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: pageTransitionSpeed / 1000,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
}

const Layout = ({ site = {}, page = {}, schema, children }) => {
  const router = useRouter()
  // set window height var
  const { height: windowHeight } = useWindowSize()

  const getFooterSocial = site?.footer?.blocks?.filter((b) => b?.social)[0]
    ?.social

  useEffect(() => {
    if (isBrowser)
      document.body.style.setProperty('--vh', `${windowHeight * 0.01}px`)
  }, [windowHeight])

  return (
    <>
      <HeadSEO site={site} page={page} />
      {schema && (
        <Script
          id="json-ld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}
      <Header data={site.header} social={getFooterSocial} />
      <m.div initial="initial" animate="enter" exit="exit" variants={variants}>
        <main id="content">{children}</main>
      </m.div>
      <Footer data={{ ...site.footer, social: site.organization?.social }} />
    </>
  )
}

export default Layout
