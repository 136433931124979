import React from 'react'
import NextLink from 'next/link'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { getPageLink } from '@lib/helpers'

const Link = React.forwardRef(
  (
    { link, link: { page } = {}, children, shallow = true, onClick, ...rest },
    ref
  ) => {
    const isLink = !!link?.url
    const router = useRouter()

    const isOverlay = () => {
      if (!shallow) return false
      switch (page?.type) {
        case 'page':
          return page?.isOverlay
        case 'artist':
        case 'story':
          return true
        default:
          return false
      }
    }

    const openOverlay = (e) => {
      e.preventDefault()
      window.history.pushState(null, null, getPageLink(link))
      const pushChangeEvent = new CustomEvent(`pushstate`, {
        detail: {
          back: router.asPath,
        },
      })
      window.dispatchEvent(pushChangeEvent)
    }

    const handleClick = (e) => {
      if (isOverlay()) openOverlay(e)
      if (onClick) onClick(e)
    }

    // External Link
    if (isLink) {
      return (
        <a
          href={link.url}
          target={!link.url.match('^mailto:') ? '_blank' : null}
          rel="noopener noreferrer"
          className={
            link?.isButton
              ? cx('btn', link.styles?.style, {
                  'is-large': link.styles?.isLarge,
                  'is-block': link.styles?.isBlock,
                })
              : null
          }
          {...rest}
        >
          {children || link?.title || page?.title}
        </a>
      )
      // Internal Page
    } else {
      const href = getPageLink(link)

      return (
        <NextLink
          href={href}
          scroll={false}
          prefetch={false}
          onClick={handleClick}
          className={
            link?.isButton
              ? cx('btn', link.styles?.style, {
                  'is-large': link.styles?.isLarge,
                  'is-block': link.styles?.isBlock,
                })
              : null
          }
          ref={ref}
          {...rest}
        >
          {children || link?.title || page?.title}
        </NextLink>
      )
    }
  }
)

export default Link
